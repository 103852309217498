import { DisplayButton } from '@xo-union/tk-ui-links';
import React, { useState } from 'react';
import Styles from './MessageAssistance.scss';

interface MessageAssistanceProps {
	closeMessageAssistance: () => void;
}

const MessageAssistance: React.FC<MessageAssistanceProps> = ({
	closeMessageAssistance,
}) => {
	const [step, setStep] = useState(1);

	return (
		<div className={Styles.container}>
			<DisplayButton
				type="button"
				onClick={closeMessageAssistance}
				color="primary"
			>
				Cancel
			</DisplayButton>
			<br />
			{step === 1 && (
				<div>
					<br />
					Step 1 placeholder
					<br />
				</div>
			)}
			{step === 2 && (
				<div>
					<br />
					Step 2 placeholder
					<br />
				</div>
			)}
			<br />
			<DisplayButton
				onClick={() => setStep(step === 1 ? 2 : 1)}
				type="button"
				color="primary"
			>
				Next/prev step
			</DisplayButton>
		</div>
	);
};

export default MessageAssistance;
