import ExpandingIconButton from '@components/ExpandingIconButton/ExpandingIconButton';
import { useAppSelector } from '@redux/hooks';
import { selectIsMobile } from '@redux/viewport/selectors';
import { compose } from '@xo-union/react-css-modules';
import { InlineAlert } from '@xo-union/tk-component-alerts';
import classNames from 'classnames';
import React, { useState, useEffect, type FC } from 'react';
import type { useInlineRfqForm } from '../../../../hooks/useInlineRfqForm/useInlineRfqForm';
import { useTrackInlineForm } from '../../../../hooks/useTrackInlineForm/use-track-inline-form';
import TextareaField from '../TextareaField/TextareaField';
import MicrophoneIcon from './MicrophoneIcon';
import Styles from './TextareaFieldWithVoiceBtn.scss';

export type Props = {
	context: ReturnType<typeof useInlineRfqForm>;
};

const LOCAL_STORAGE_KEY = 'voiceButtonClicked';

export const TextareaFieldWithVoiceBtn: FC<Props> = ({ context }) => {
	const [showButton, setShowButton] = useState(true);
	const [showMessage, setShowMessage] = useState(false);
	const [showMessageConfirmation, setShowMessageConfirmation] = useState(false);

	useEffect(() => {
		const isClicked = localStorage?.getItem(LOCAL_STORAGE_KEY) === 'clicked';
		setShowButton(!isClicked);
	}, []);

	const onButtonClick = () => {
		trackShowMessage();
		localStorage?.setItem(LOCAL_STORAGE_KEY, 'clicked');
		setShowMessage(true);
		setShowButton(false);
	};

	const onMessageClick = () => {
		trackShowMessageConfirmation();
		setShowMessage(false);
		setShowMessageConfirmation(true);
	};

	// Force trigger on first tap for mobile
	const isMobile = useAppSelector(selectIsMobile);
	const [forcedExpanded, setForcedExpanded] = useState(false);
	const [hasTappedTextarea, setHasTappedTextarea] = useState(false);
	const triggerExpansion = () => {
		if (isMobile) {
			setForcedExpanded(true);
			setTimeout(() => setForcedExpanded(false), 1000);
		}
	};

	const handleFirstTextareaClick = () => {
		if (!hasTappedTextarea) {
			setHasTappedTextarea(true);
			triggerExpansion();
		}
	};

	// tracking
	const trackHoverButton = useTrackInlineForm(
		'Audio message hover',
		context.initiator,
	);
	const trackShowMessage = useTrackInlineForm(
		'Audio message',
		context.initiator,
	);
	const trackShowMessageConfirmation = useTrackInlineForm(
		'Audio message confirmation',
		context.initiator,
	);

	return (
		<>
			<div
				className={classNames(Styles.wrapper, {
					[Styles.notEmpty]: context.values.textarea.value,
				})}
				data-testid="textarea-with-voice-btn"
			>
				<div
					onKeyDown={handleFirstTextareaClick}
					onClick={handleFirstTextareaClick}
				>
					<TextareaField context={context} isRequired />
				</div>
				{showButton && (
					<ExpandingIconButton
						onClick={onButtonClick}
						onMouseEnter={() => trackHoverButton()}
						colorScheme="additional01"
						text="Record voice message"
						IconComponent={<MicrophoneIcon />}
						forcedExpanded={forcedExpanded}
					/>
				)}
			</div>
			{showMessage && (
				<InlineAlert
					classes={compose({
						container: Styles.inlineAlert,
					})}
					type="info"
					icon={false}
					onCloseButtonClick={() => setShowMessage(false)}
				>
					<span>
						Voice messaging isn't available in your area yet. Interested?{' '}
					</span>
					<span
						className={Styles.fakeLink}
						onClick={onMessageClick}
						onKeyDown={onMessageClick}
					>
						I'd use this!
					</span>
				</InlineAlert>
			)}
			{showMessageConfirmation && (
				<InlineAlert
					className={Styles.inlineAlert}
					type="info"
					icon={false}
					onCloseButtonClick={() => setShowMessageConfirmation(false)}
				>
					Thanks for your feedback! We appreciate your help.
				</InlineAlert>
			)}
		</>
	);
};
