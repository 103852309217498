import SegmentedControl, {
	type SegmentedControlOption,
} from '@components/SegmentedControl/SegmentedControl';
import { Body1, Caption } from '@xo-union/tk-ui-typography';
import React, { type FC } from 'react';
import type { useInlineRfqForm } from '../../../../hooks/useInlineRfqForm/useInlineRfqForm';
import { handleFieldChange } from '../../../../utils';
import { getFieldLabel } from '../helpers';
import Styles from './GuestCountSegmentedControl.scss';

export interface GuestCountSegmentedControlProps {
	context: ReturnType<typeof useInlineRfqForm>;
	isRequired?: boolean;
}

export const options: SegmentedControlOption[] = [
	// ! No normal hypen - but en dash –
	{ label: '0–100', value: '0–100' },
	{ label: '101–200', value: '101–200' },
	{ label: '201–300', value: '201–300' },
	{ label: '300+', value: '301 or more' },
];

export const GuestCountSegmentedControl: FC<GuestCountSegmentedControlProps> = (
	props,
) => {
	const { context, isRequired = false } = props;
	const { setFieldValue, shouldShowErrors } = context;
	const { guestCount: contextValue } = context.values;

	const handleGuestCountChange = (value: string) => {
		handleFieldChange({
			cb: setFieldValue,
			data: contextValue,
			name: 'guestCount',
			value,
		});
	};

	return (
		<>
			<Body1 className={Styles.bodyText}>
				{getFieldLabel(contextValue.label, isRequired)}
			</Body1>
			{shouldShowErrors && (
				<Caption size="sm" className={Styles.errorText}>
					{contextValue.error}
				</Caption>
			)}
			<div className={Styles.segmentedControlWrapper}>
				<SegmentedControl
					options={options}
					name="guestCount"
					onChange={handleGuestCountChange}
					selectedValue={contextValue.value}
					fixedWidth={false}
					isIndicatorAnimated={true}
				/>
			</div>
		</>
	);
};

export default GuestCountSegmentedControl;
