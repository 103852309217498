import * as React from 'react';

const RecordVoiceIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
	>
		<title>Record voice</title>
		<path
			stroke="currentColor"
			d="M8.5 6C8.5 4.06701 10.067 2.5 12 2.5C13.933 2.5 15.5 4.067 15.5 6V10.9412C15.5 12.8742 13.933 14.4412 12 14.4412C10.067 14.4412 8.5 12.8742 8.5 10.9412V6Z"
		/>
		<path
			fill="currentColor"
			d="M19 12C19 12.8043 18.8189 13.6008 18.4672 14.3439C18.1154 15.0871 17.5998 15.7623 16.9497 16.331C16.2997 16.8998 15.5281 17.351 14.6788 17.6588C13.8295 17.9666 12.9193 18.125 12 18.125C11.0807 18.125 10.1705 17.9666 9.32122 17.6588C8.47194 17.351 7.70026 16.8998 7.05025 16.331C6.40024 15.7623 5.88463 15.0871 5.53284 14.3439C5.18106 13.6008 5 12.8043 5 12H6.08846C6.08846 12.6793 6.24136 13.3519 6.53844 13.9795C6.83553 14.607 7.27097 15.1773 7.81991 15.6576C8.36884 16.1379 9.02053 16.5189 9.73775 16.7789C10.455 17.0388 11.2237 17.1726 12 17.1726C12.7763 17.1726 13.545 17.0388 14.2622 16.7789C14.9795 16.5189 15.6312 16.1379 16.1801 15.6576C16.729 15.1773 17.1645 14.607 17.4616 13.9795C17.7586 13.3519 17.9115 12.6793 17.9115 12H19Z"
		/>
		<path
			fill="currentColor"
			d="M11.4414 21.4706C11.4414 21.7467 11.6653 21.9706 11.9414 21.9706C12.2175 21.9706 12.4414 21.7467 12.4414 21.4706H11.4414ZM11.4414 17.9412V21.4706H12.4414V17.9412H11.4414Z"
		/>
	</svg>
);

export default RecordVoiceIcon;
