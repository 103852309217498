import { useRfqFakeAiAssistantTest } from '@feature-flags/hooks/useRfqFakeAiAssistantTest';
import { useRfqFakeVoiceMessaging } from '@feature-flags/hooks/useRfqFakeVoiceMessaging';
import { isEmailDomainSuggesterSelector } from '@redux/experiments/selectors/email-domain-suggester';
import { rfqGuestCountFormatSelector } from '@redux/experiments/selectors/rfq-guest-count-format';
import { isRfqMessageAssistanceSelector } from '@redux/experiments/selectors/rfq-message-assistance';
import { useAppSelector } from '@redux/hooks';
import { compose } from '@xo-union/react-css-modules';
import React, { useState, type FC } from 'react';
import type { useInlineRfqForm } from '../../hooks/useInlineRfqForm/useInlineRfqForm';
import { DEFAULT_EMAIL_DOMAIN_SUGGESTIONS } from '../../utils';
import Styles from './FormBody.scss';
import DateFlexible from './components/DateFlexible/DateFlexible';
import DatePickerField from './components/DatePickerField/DatePickerField';
import GuestCountSegmentedControl from './components/GuestCountSegmentedControl/GuestCountSegmentedControl';
import GuestCountSelect from './components/GuestCountSelect/GuestCountSelect';
import InputField from './components/InputField/InputField';
import MaskPhoneField from './components/MaskPhoneField/MaskPhoneField';
import TextareaField from './components/TextareaField/TextareaField';
import { TextareaFieldWithAiAssistantBtn } from './components/TextareaFieldWithAiAssistantBtn/TextareaFieldWithAiAssistantBtn';
import { TextareaFieldWithGenerateMsgBtn } from './components/TextareaFieldWithGenerateMsgBtn/TextareaFieldWithGenerateMsgBtn';
import { TextareaFieldWithVoiceBtn } from './components/TextareaFieldWithVoiceBtn/TextareaFieldWithVoiceBtn';
import TypeaheadInputField from './components/TypeaheadInputField/TypeaheadInputField';

export interface FormBodyProps {
	context: ReturnType<typeof useInlineRfqForm>;
	openMessageAssistance: () => void;
}

export const FormBody: FC<FormBodyProps> = ({
	context,
	openMessageAssistance,
}) => {
	const [emailSuggestions, setEmailSuggestions] = useState<string[]>([]);

	const shouldShowFakeVoiceMessaging = useRfqFakeVoiceMessaging();
	const shouldShowFakeAiAssistantTest = useRfqFakeAiAssistantTest();
	const isEmailDomainSuggesterVariant = useAppSelector(
		isEmailDomainSuggesterSelector,
	);
	const rfqGuestCountFormatAssignment = useAppSelector(
		rfqGuestCountFormatSelector,
	);
	const isRfqMessageAssistanceVariant = useAppSelector(
		isRfqMessageAssistanceSelector,
	);

	const containerClassNames = {
		container: Styles.container,
		'sub-text': Styles.subtext,
	};
	const inputClassNames = {
		...containerClassNames,
		input: 'fs-mask',
	};
	const datePickerClassNames = {
		container: Styles.datepickerContainer,
	};
	const emailTypeaheadClassNames = {
		...inputClassNames,
		'list-container': emailSuggestions.length
			? Styles.listContainer
			: Styles.listContainerHidden,
	};

	const handleEmailSearch = (query: string) => {
		// FYI: query is received as automatically trimmed
		if (!query.includes('@')) {
			setEmailSuggestions([]);
			return;
		}

		const validQuery = query.replace(/\s/g, '');
		const emailPrefix = validQuery.slice(0, validQuery.indexOf('@'));
		const emailSuffix = validQuery.slice(validQuery.indexOf('@') + 1);
		let emailDomainSuggestions = DEFAULT_EMAIL_DOMAIN_SUGGESTIONS;

		if (emailSuffix) {
			emailDomainSuggestions = emailDomainSuggestions.filter((domain) =>
				domain.startsWith(emailSuffix),
			);
		}
		setEmailSuggestions(
			emailDomainSuggestions.map((domain) => `${emailPrefix}@${domain}`),
		);
	};

	return (
		<form>
			<div className={Styles.namesContainer}>
				<InputField
					context={context}
					classes={compose(inputClassNames)}
					name="firstName"
					autoComplete="given-name"
					isRequired
				/>
				<InputField
					context={context}
					classes={compose(inputClassNames)}
					name="lastName"
					autoComplete="family-name"
					isRequired
				/>
			</div>
			{isEmailDomainSuggesterVariant ? (
				<TypeaheadInputField
					context={context}
					classes={compose(emailTypeaheadClassNames)}
					name="emailAddress"
					autoComplete="email"
					isRequired
					suggestions={emailSuggestions}
					handleTypeaheadSearch={handleEmailSearch}
					removeWhitespaces
				/>
			) : (
				<InputField
					context={context}
					classes={compose(inputClassNames)}
					name="emailAddress"
					autoComplete="email"
					isRequired
				/>
			)}
			<DatePickerField
				context={context}
				datePickerStyle={compose(datePickerClassNames)}
				isRequired
			/>
			<DateFlexible context={context} />
			{rfqGuestCountFormatAssignment?.startsWith('segmented_control') ? (
				<GuestCountSegmentedControl context={context} isRequired />
			) : rfqGuestCountFormatAssignment?.startsWith('slider') ? (
				// Placeholder for slider
				<GuestCountSegmentedControl context={context} isRequired />
			) : (
				<GuestCountSelect
					context={context}
					selectStyle={compose(containerClassNames)}
					isRequired
				/>
			)}
			<MaskPhoneField context={context} classes={compose(inputClassNames)} />
			{shouldShowFakeAiAssistantTest ? (
				<TextareaFieldWithAiAssistantBtn context={context} />
			) : isRfqMessageAssistanceVariant ? (
				<TextareaFieldWithGenerateMsgBtn
					context={context}
					onClick={openMessageAssistance}
				/>
			) : shouldShowFakeVoiceMessaging ? (
				<TextareaFieldWithVoiceBtn context={context} />
			) : (
				<TextareaField context={context} isRequired />
			)}
		</form>
	);
};

export default FormBody;
