import ExpandingIconButton from '@components/ExpandingIconButton/ExpandingIconButton';
import { compose } from '@xo-union/react-css-modules';
import { InlineAlert } from '@xo-union/tk-component-alerts';
import Icon from '@xo-union/tk-component-icons';
import classNames from 'classnames';
import React, { useState, useEffect, type FC } from 'react';
import type { useInlineRfqForm } from '../../../../hooks/useInlineRfqForm/useInlineRfqForm';
import { useTrackInlineForm } from '../../../../hooks/useTrackInlineForm/use-track-inline-form';
import TextareaField from '../TextareaField/TextareaField';
import Styles from './TextareaFieldWithAiAssistantBtn.scss';

export type Props = {
	context: ReturnType<typeof useInlineRfqForm>;
};

const LOCAL_STORAGE_KEY = 'aiAssistantBtnClicked';

export const TextareaFieldWithAiAssistantBtn: FC<Props> = ({ context }) => {
	const [showButton, setShowButton] = useState(true);
	const [showMessage, setShowMessage] = useState(false);
	const [showMessageConfirmation, setShowMessageConfirmation] = useState(false);

	// For syncing the current textarea value length
	const [textareaValueLength, setTextareaValueLength] = useState(
		context?.values?.textarea?.value?.length || 0,
	);

	useEffect(() => {
		const isClicked = localStorage?.getItem(LOCAL_STORAGE_KEY) === 'clicked';
		setShowButton(!isClicked);
	}, []);

	const onButtonClick = () => {
		trackShowMessage();
		localStorage?.setItem(LOCAL_STORAGE_KEY, 'clicked');
		setShowMessage(true);
		setShowButton(false);
	};

	const onMessageClick = () => {
		trackShowMessageConfirmation();
		setShowMessage(false);
		setShowMessageConfirmation(true);
	};

	// tracking
	const trackShowMessage = useTrackInlineForm(
		'On demand AI message',
		context.initiator,
	);
	const trackShowMessageConfirmation = useTrackInlineForm(
		'On demand AI message confirmation',
		context.initiator,
	);

	return (
		<>
			<div
				className={classNames(Styles.wrapper, {
					[Styles.notEmpty]: context?.values?.textarea?.value,
					[Styles.overlappingTextareaValue]: textareaValueLength > 80,
				})}
				data-testid="textarea-with-ai-assistant-btn"
			>
				<TextareaField
					context={context}
					isRequired
					onChange={(val) => {
						setTextareaValueLength(val?.length || 0);
					}}
				/>
				{showButton && (
					<ExpandingIconButton
						onClick={onButtonClick}
						colorScheme="primary"
						text="AI assistant"
						IconComponent={<Icon name="ai_sparkle" size="md" />}
						forcedExpanded={true}
					/>
				)}
			</div>
			{showMessage && (
				<InlineAlert
					classes={compose({
						container: Styles.inlineAlert,
					})}
					type="info"
					icon={false}
					onCloseButtonClick={() => setShowMessage(false)}
				>
					<span>
						This feature isn't available in your area yet. Interested?{' '}
					</span>
					<span
						className={Styles.fakeLink}
						onClick={onMessageClick}
						onKeyDown={onMessageClick}
					>
						Yes, I'd use this AI assistant!
					</span>
				</InlineAlert>
			)}
			{showMessageConfirmation && (
				<InlineAlert
					className={Styles.inlineAlert}
					type="info"
					icon={false}
					onCloseButtonClick={() => setShowMessageConfirmation(false)}
				>
					Thanks for your feedback! We appreciate your help.
				</InlineAlert>
			)}
		</>
	);
};
